export default {
  name: 'sims',
  icon: 'mdi-sim',
  graphTypeName: 'Sim',
  listQueryName: 'sims',
  singleQueryName: 'sim',
  create: {
    mutationName: 'createSim',
    inputType: 'CreateSimInput!'
  },
  update: {
    mutationName: 'updateSim',
    inputType: 'UpdateSimInput!'
  },
  fields: [
    {
      name: 'region',
      type: 'Region'
    },
    {
      name: 'countryIsoCode2',
      type: 'Country'
    },
    {
      name: 'assignee',
      type: 'Assignee'
    },
    {
      name: 'primaryUser',
      type: 'IntraAccount'
    },
    {
      name: 'histories',
      type: '[History]'
    },
    {
      name: 'id',
      type: 'ID!'
    },
    {
      name: 'office',
      type: 'Location'
    },
    {
      name: 'company',
      type: 'Company'
    },
    {
      name: 'memo',
      type: 'String'
    },
    {
      name: 'costCode',
      type: 'String'
    },
    {
      name: 'model',
      type: 'Model'
    },
    {
      name: 'name',
      type: 'String!',
      displayName: 'Phone number'
    },
    {
      name: 'purchaseCost',
      type: 'Decimal'
    },
    {
      name: 'purchaseCurrencyCode',
      type: 'CurrencyCode'
    },
    {
      name: 'purchaseDate',
      type: 'Date'
    },
    {
      name: 'serialNumber',
      type: 'String',
      displayName: 'ICCID'
    },
    {
      name: 'docomoSerialNumber',
      type: 'String'
    },
    {
      name: 'status',
      type: 'AssetStatus!'
    },
    {
      name: 'operationStatus',
      type: 'OperationStatus!'
    },
    {
      name: 'warrantyExpires',
      type: 'Date'
    },
    {
      name: 'simSlot',
      displayName: 'Asset',
      type: 'SimSlot',
      extra: false,
      readOnly: true,
      fields: [
        'iMEI',
        {
          name: 'asset',
          type: 'Asset',
          extra: false,
          readOnly: true,
          fields: ['id', 'name']
        }
      ]
    },
    {
      name: 'contractStartDate',
      type: 'Date',
      extra: true,
      readOnly: false
    },
    {
      name: 'contractEndDate',
      type: 'Date',
      extra: true,
      readOnly: false
    },
    {
      name: 'viberNumber',
      type: 'String',
      extra: true,
      readOnly: false
    },
    {
      name: 'cmanageModel',
      type: 'String',
      extra: true,
      readOnly: true
    },
    {
      name: 'mailAddress',
      type: 'String',
      extra: true,
      readOnly: false
    },
    {
      name: 'noticeMailAddress',
      type: 'String',
      extra: true,
      readOnly: false
    },
    {
      name: 'rMobileSupportId',
      type: 'String',
      extra: true,
      readOnly: false
    },
    {
      name: 'rMobileSupportPasswordVersion',
      displayName: 'R Mobile Support Password',
      type: 'Password',
      extra: true,
      readOnly: false
    },
    {
      name: 'useType',
      type: 'SimUseType',
      extra: true,
      readOnly: false
    }
  ],
  importFields: [
    'Id',
    'ICCID',        // SerialNumber
    'PhoneNumber',  // Name
    'Status',
    'OperationStatus',
    'ModelNumber',
    'ModelName',
    'ModelYear',
    'Carrier',
    'Office',
    'Region',
    'Country',
    'Assignee',
    'PrimaryUser',
    'Company',
    'PurchaseCost',
    'PurchaseDate',
    'WarrantyExpires',
    'ViberNumber',
    'ContractStartDate',
    'ContractEndDate',
    'MailAddress',
    'NoticeMailAddress',
    'UseType',
    'Asset',
    'CostCode',
    'Memo'
    /*
      Ignored fields:
        IsDeleted - Cannot be modified by import
        CmanageModel - Read only, not used to locate which model to use when importing
        AssigneeName - Read only field just for operator reference, 'Assignee' is the actual field that import uses
        AssigneeEmployeeId - Same as AssigneeName
        PrimaryUserName - Read only field just for operator reference, 'PrimaryUser' is the actual field that import uses
        PrimaryUserEmployeeId - Same as PrimaryUserName
        RMobileSupportId - Not needed atm, can add if needed
        CamId - Read only field
     */
  ],
  headers: [
    {
      text: 'Phone Number',
      value: 'name',
      fixed: true
    },
    {
      text: 'ICCID',
      value: 'serialNumber',
      fixed: true
    },
    {
      text: 'Assignee',
      value: 'assignee.samAccountName'
    },
    {
      text: 'PrimaryUser',
      value: 'primaryUser.samAccountName'
    },
    {
      text: 'Company',
      value: 'company.name'
    },
    {
      text: 'Carrier',
      value: 'model.manufacturer.name'
    },
    {
      text: 'Memo',
      value: 'memo'
    },
    {
      text: 'Model',
      value: 'model.name'
    },
    {
      text: 'Model Number',
      value: 'model.modelNumber'
    },
    {
      text: 'Purchase Cost',
      value: 'purchaseCost'
    },
    {
      text: 'Purchase Currency Code',
      value: 'purchaseCurrencyCode'
    },
    {
      text: 'Purchase Date',
      value: 'purchaseDate'
    },
    {
      text: 'Region',
      value: 'region'
    },
    {
      text: 'Country',
      value: 'countryIsoCode2'
    },
    {
      text: 'Office',
      value: 'office.name'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'OperationStatus',
      value: 'operationStatus'
    },
    {
      text: 'Warranty Expires',
      value: 'warrantyExpires'
    },
    {
      text: 'Cost Code',
      value: 'costCode'
    },
    {
      text: 'Contract Start Date',
      value: 'contractStartDate'
    },
    {
      text: 'Contract End Date',
      value: 'contractEndDate'
    },
    {
      text: 'Viber Number',
      value: 'viberNumber'
    },
    {
      text: 'Use Type',
      value: 'useType'
    },
    {
      text: 'Mail Address',
      value: 'mailAddress'
    },
    {
      text: 'Notice Mail Address',
      value: 'noticeMailAddress'
    },
    {
      text: 'Mobile',
      value: 'simSlot.asset.name'
    },
    {
      text: 'R-Mobile Support Id',
      value: 'rMobileSupportId'
    }
  ]
}
