import GraphQlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [GraphQlMixin, FormattingMixin],
  data() {
    return {
      regions: [
        {
          text: 'Americas',
          defaultCurrencyCode: 'USD'
        },
        {
          text: 'Asia',
          defaultCurrencyCode: 'SGD'
        },
        {
          text: 'Europe',
          defaultCurrencyCode: 'EUR'
        },
        {
          text: 'Japan',
          defaultCurrencyCode: 'JPY'
        }
      ],
      simUseTypes: [
        {
          text: 'TECH',
          value: 'TECH'
        },
        {
          text: 'ECC',
          value: 'ECC'
        },
        {
          text: 'TRAVEL',
          value: 'TRAVEL'
        },
        {
          text: 'eSim-all emp',
          value: 'ESIM_ALL_EMP'
        },
        {
          text: 'eSim-Rmobile',
          value: 'ESIM_RMOBILE'
        },
        {
          text: 'AppDev',
          value: 'APP_DEV'
        },
        {
          text: 'iPad',
          value: 'IPAD'
        },
        {
          text: 'Laptop',
          value: 'LAPTOP'
        },
        {
          text: 'WiFi-all emp',
          value: 'WIFI_ALL_EMP'
        },
        {
          text: 'OTHERS',
          value: 'OTHERS'
        }
      ],
      computerUsages: [
        {
          text: 'Standard',
          value: 'STANDARD'
        },
        {
          text: 'Mobile',
          value: 'MOBILE'
        },
        {
          text: 'Shared',
          value: 'SHARED'
        },
        {
          text: 'Meeting',
          value: 'MEETING'
        },
        {
          text: 'Loaner',
          value: 'LOANER'
        },
        {
          text: 'Non Intra',
          value: 'NON_INTRA'
        },
        {
          text: 'Other',
          value: 'OTHER'
        }
      ],
      // computerRentalPeriods: [
      //   {
      //     text: '1 Year',
      //     value: '1year'
      //   },
      //   {
      //     text: '2 Year',
      //     value: '2year'
      //   },
      //   {
      //     text: '3 Year',
      //     value: '3year'
      //   },
      //   {
      //     text: '1 Month',
      //     value: '1month'
      //   },
      //   {
      //     text: '2 Month',
      //     value: '2month'
      //   },
      //   {
      //     text: '3 Month',
      //     value: '3month'
      //   }
      // ],
      statuses: [
        {
          value: 'IN_USE',
          text: 'In Use',
          color: 'rgba(33,150,243,1.0)'
        },
        {
          value: 'IN_STOCK',
          text: 'In Stock',
          color: 'rgba(76,175,80,1.0)'
        },
        {
          value: 'DECOMMISSIONED',
          text: 'Decommissioned',
          color: 'rgba(158,158,158,1.0)'
        }
      ],
      pclStatuses: [
        {
          value: 'ALMOST_NEW',
          text: 'Almost New',
          status: 'IN_USE'
        },
        {
          value: 'SECOND_HAND',
          text: 'Second Hand',
          status: 'IN_USE'
        },
        {
          value: 'NEW',
          text: 'New',
          status: 'IN_STOCK'
        },
        {
          value: 'ALMOST_NEW',
          text: 'Almost New',
          status: 'IN_STOCK'
        },
        {
          value: 'SECOND_HAND',
          text: 'Second Hand',
          status: 'IN_STOCK'
        },
        {
          value: 'ALMOST_NEW',
          text: 'Almost New',
          status: 'DECOMMISSIONED'
        },
        {
          value: 'SECOND_HAND',
          text: 'Second Hand',
          status: 'DECOMMISSIONED'
        }
      ],
      shareStatuses: [
        {
          value: 'IN_USE',
          text: 'In Use'
        },
        {
          value: 'IN_STOCK',
          text: 'In Stock'
        },
        {
          value: 'NON_INTRA',
          text: 'Non Intra'
        }
      ],
      pcTypes: [
        { value: 'INTRA', text: 'Intra' },
        { value: 'NON_INTRA', text: 'Non Intra' }
      ],
      pcSpecifications: [
        { value: 'GENERAL', text: 'General' },
        { value: 'CUSTOM', text: 'Custom' },
        { value: 'SPECIAL', text: 'Special' }
      ],
      models: []
    }
  },
  computed: {
    operationStatuses() {
      return [
        {
          value: 'STORED',
          text: 'Stored',
          status: this.getStatus('STORED')
        },
        {
          value: 'MAINTENANCE',
          text: 'Maintenance',
          status: this.getStatus('MAINTENANCE')
        },
        {
          value: 'DECOMMISSION_IN_PROGRESS',
          text: 'Decommission In Progress',
          status: this.getStatus('DECOMMISSION_IN_PROGRESS')
        },
        {
          value: 'UNDER_REPAIR',
          text: 'Under Repair',
          status: this.getStatus('UNDER_REPAIR')
        },
        {
          value: 'UNDER_REPAIR',
          text: 'Under Repair',
          status: this.getStatus('UNDER_REPAIR_2')
        },
        {
          value: 'KITTING_COMPLETED',
          text: 'Kitting Completed',
          status: this.getStatus('KITTING_COMPLETED')
        },
        {
          value: 'DEPLOYED',
          text: 'Deployed',
          status: this.getStatus('DEPLOYED')
        },
        {
          value: 'LOST',
          text: 'Lost',
          status: this.getStatus('LOST')
        },
        {
          value: 'RETIRED',
          text: 'Retired',
          status: this.getStatus('RETIRED')
        },
        {
          value: 'HOLD_CITD',
          text: 'Hold - CITD',
          status: this.getStatus('HOLD_CITD')
        },
        {
          value: 'HOLD_HR',
          text: 'Hold - HR',
          status: this.getStatus('HOLD_HR')
        },
        {
          value: 'HOLD_LEGAL',
          text: 'Hold - Legal',
          status: this.getStatus('HOLD_LEGAL')
        },
        {
          value: 'HOLD_TERMINATION',
          text: 'Hold - Termination',
          status: this.getStatus('HOLD_TERMINATION')
        },
        {
          value: 'HOLD_DECOMMISSION',
          text: 'Hold - Decommission',
          status: this.getStatus('HOLD_DECOMMISSION')
        },
        {
          value: 'HOLD_MALWARE',
          text: 'Hold - Malware',
          status: this.getStatus('HOLD_MALWARE')
        },
        {
          value: 'HOLD_OTHER',
          text: 'Hold - Other',
          status: this.getStatus('HOLD_OTHER')
        },
        {
          value: 'DEPARTED_RETURNED',
          text: 'Departed - Returned',
          status: this.getStatus('DEPARTED_RETURNED')
        },
        {
          value: 'DEPARTED_PENDING',
          text: 'Departed - Pending',
          status: this.getStatus('DEPARTED_PENDING')
        },
        {
          value: 'RETURNED',
          text: 'Returned',
          status: this.getStatus('RETURNED')
        },
        {
          value: 'PENDING_RETURN',
          text: 'Pending Return',
          status: this.getStatus('PENDING_RETURN')
        },
        {
          value: 'REISSUED',
          text: 'Reissued',
          status: this.getStatus('REISSUED')
        }
      ]
    },
    statusMap() {
      return this.statuses.reduce(
        (map, obj) => ((map[obj.value] = obj), map),
        {}
      )
    },
    operationStatusMap() {
      return this.operationStatuses.reduce(
        (map, obj) => ((map[obj.value] = obj), map),
        {}
      )
    },
    pclStatusMap() {
      return this.pclStatuses.reduce(
        (map, obj) => ((map[obj.value] = obj), map),
        {}
      )
    },
    shareStatusMap() {
      return this.shareStatuses.reduce(
        (map, obj) => ((map[obj.value] = obj), map),
        {}
      )
    },
    extraFields() {
      return this.currentType.fields.filter((f) => f.extra)
    },
    readOnlyFields() {
      return this.currentType.fields.filter((f) => f.readOnly && f.fields)
    },
    extraOrReadOnlyFields() {
      return this.currentType.fields.filter(
        (f) => f.extra || (f.readOnly && f.fields)
      )
    },
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp
      }
    },
    isAssetPage() {
      return [
        'list-assets',
        'show-asset',
        'create-asset',
        'edit-asset'
      ].includes(this.$route.name)
    },
    isAssetFilterPage() {
      return this.$route.name === 'show-asset-filter'
    },
    isAllAssetsPage() {
      return this.isAssetPage && this.$route.params.type === 'allAssets'
    },
    isTrashPage() {
      return this.$route.name === 'list-deleted-assets'
    },
    currentType() {
      if (this.assetGraphType) {
        return (
          this.$store.getters['graphql/assetTypes'].find(
            (x) => x.graphTypeName === this.assetGraphType
          ) || this.$store.getters['graphql/assetType']
        )
      } else if (this.isAssetPage) {
        return this.$store.getters['graphql/assetType']
      } else if (this.isAssetFilterPage) {
        return (
          this.$store.getters['graphql/assetType'] ||
          this.$store.getters['graphql/assetTypes'].find(
            (x) => x.graphTypeName === _.get(this, 'assetFilter.type', 'Asset')
          )
        )
      } else if (this.isTrashPage) {
        return this.$store.getters['graphql/assetType']
      }
    },
    ...mapGetters('graphql', ['assetTypes'])
  },
  watch: {
    // Everytime the route changes, reset search
    $route(to, from) {
      if (to.path !== from.path) {
        this.search = ''
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path) {
      this.search = ''
    }
    next()
  },
  methods: {
    getStatus(operationStatus) {
      let assetType = 'Asset'
      if (this.currentType) {
        assetType = this.currentType.graphTypeName
      }
      switch (assetType) {
        case 'Computer':
          switch (operationStatus) {
            case 'LOST':
            case 'RETIRED':
            case 'DECOMMISSION_IN_PROGRESS':
              return 'DECOMMISSIONED'
            case 'HOLD_TERMINATION':
            case 'DEPARTED_PENDING':
            case 'REISSUED':
            case 'PENDING_RETURN':
            case 'UNDER_REPAIR':
            case 'MAINTENANCE':
            case 'DEPLOYED':
              return 'IN_USE'
            case 'STORED':
            case 'UNDER_REPAIR_2':
            case 'KITTING_COMPLETED':
            case 'HOLD_CITD':
            case 'HOLD_MALWARE':
            case 'HOLD_DECOMMISSION':
            case 'HOLD_LEGAL':
            case 'HOLD_OTHER':
            case 'HOLD_HR':
            case 'RETURNED':
            default:
              return 'IN_STOCK'
          }
        case 'Mobile':
        case 'Sim':
        case 'SecurityDevice':
        default:
          switch (operationStatus) {
            case 'LOST':
            case 'RETIRED':
            case 'DECOMMISSION_IN_PROGRESS':
              return 'DECOMMISSIONED'
            case 'REISSUED':
            case 'PENDING_RETURN':
            case 'DEPARTED_PENDING':
            case 'HOLD_TERMINATION':
            case 'DEPLOYED':
              return 'IN_USE'
            case 'UNDER_REPAIR':
            case 'HOLD_HR':
            case 'HOLD_LEGAL':
            case 'HOLD_DECOMMISSION':
            case 'HOLD_MALWARE':
            case 'HOLD_OTHER':
            case 'DEPARTED_RETURNED':
            case 'STORED':
            case 'RETURNED':
            default:
              return 'IN_STOCK'
          }
      }
    },
    getAssetQuery() {
      const allFields = [
        'id',
        'name',
        'purchaseCost',
        'purchaseCurrencyCode',
        'purchaseDate',
        'serialNumber',
        'status',
        'operationStatus',
        'isDeleted',
        'isCloned',
        'region',
        'countryIsoCode2',
        {
          name: 'model',
          type: 'Model',
          fields: ['id', 'name', 'modelNumber', 'modelYear', 'assetType', { name: 'manufacturer', type: 'Manufacturer', fields: ['id', 'name'] }]
        },
        { name: 'office', type: 'Location', fields: ['id', 'name', 'region', 'address', 'defaultCurrencyCode', 'countryIsoCode2'] },
        {
          name: 'assignee',
          type: 'Assignee',
          fields: [
            'id',
            {
              type: 'IntraAccount',
              fields: [
                'samAccountName',
                'employeeId',
                'name',
                'countryIsoCode2',
                {
                  name: 'location',
                  type: 'Location',
                  fields: ['id', 'name']
                }
              ],
              isFragment: true
            }
          ]
        },
        {
          name: 'assignee',
          type: 'Assignee',
          fields: [
            'id',
            {
              type: 'Organization',
              fields: ['externalId', 'name'],
              isFragment: true
            }
          ]
        },
        {
          name: 'primaryUser',
          type: 'IntraAccount',
          fields: ['id', 'samAccountName', 'employeeId', 'name']
        },
        {
          name: 'company',
          type: 'Company',
          fields: ['id', 'name', 'nameLocal', 'companyId', 'deletedExternally']
        },
        'warrantyExpires',
        'memo',
        'costCode'
      ]
        .concat(this.readOnlyFields)
        .concat(this.extraFields.filter((f) => !f.fields).map((f) => f.name))
        .concat(this.extraFields.filter((f) => f.fields && !f.readOnly))
      const fields = this.getFieldsGql(
        'read',
        this.currentType.graphTypeName,
        allFields
      )
      return this.$gql`
        query asset($id: ID!) {
          ${this.currentType.singleQueryName}(id: $id) {
            ${fields}
          }
        }
      `
    },
    getFieldLabel(name) {
      if (this.currentType.graphTypeName === 'Sim') {
        switch (name) {
          case 'manufacturer':
            return 'Carrier'
          case 'model':
            return 'Plan'
          default:
            break
        }
      }
      switch (name) {
        case 'isLegalHold':
          return 'Legal Hold'
        case 'countryIsoCode2':
          return 'Country'
        default:
          break
      }
      return _.get(
        _.find(this.currentType.fields, (x) => x.name === name),
        'displayName',
        _.startCase(name)
      )
    },
    getFieldLength(name) {
      switch (this.getFieldLabel(name)) {
        case 'ICCID':
          return 19
        case 'Docomo Serial Number':
          return 15
        default:
          return 64
      }
    },
    getFieldType(name) {
      switch (this.getFieldLabel(name)) {
        case 'ICCID':
          return 'number'
        default:
          return 'text'
      }
    },
    isValidUrl(input) {
      return /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
        input
      )
    },
    getFilterFieldType(f) {
      return _.replace(_.get(f, 'field.value.type'), /!/g, '')
    },
    // net 5
    filterToWhereExpression(root) {
      if (_.isEmpty(root) || _.isEmpty(root.filters)) {
        return []
      }
      return [this.filterGroupToWhere(root, root.connector)]
    },
    filterToWhere(filter, connector) {
      let where = {
        path: _.get(filter, 'field.value.name'),
        comparison: _.get(filter, 'operator.value'),
        value: '',
        negate: filter.negate,
        connector
      }
      if (
        typeof filter.value === 'object' ||
        typeof filter.value === 'undefined'
      ) {
        if (filter.field.value.type === 'Boolean') {
          where.path = `${filter.field.value.name}`
          where.value = [`${filter.value.value}`]
        } else {
          let ids = []
          let names = []
          let selections = _.castArray(filter.value)
          for (let item of selections) {
            if (_.get(item, 'id')) {
              ids.push(item.id)
            } else if (
              typeof item === 'string' ||
              item === null ||
              item === undefined
            ) {
              if (
                item === null &&
                (_.includes(filter.field.type, 'String') ||
                  (_.includes(filter.field.value.type, 'String') &&
                    (where.comparison === 'in' ||
                      where.comparison === 'equal')))
              ) {
                where.comparison = 'in'
                names = [null, '']
              } else {
                names.push(item)
              }
            }
          }
          if (ids.length > 0) {
            where.path = `${filter.field.value.name}.id`
            where.value = ids
          } else if (names.length > 0) {
            where.value = names
          }
        }
      } else {
        switch (filter.operator.value) {
          case 'equal':
          case 'greaterThan':
          case 'greaterThanOrEqual':
          case 'lessThan':
          case 'lessThanOrEqual':
          case 'contains':
          case 'startsWith':
          case 'endsWith':
            where.value = [filter.value]
            break
          case 'in':
            break
          case 'like':
            break
          case 'empty':
            break
          default:
            break
        }
      }
      return where
    },
    filterGroupToWhere(group, connector) {
      const where = {
        groupedExpressions: [],
        negate: group.negate,
        connector
      }
      for (let filter of group.filters) {
        if (filter.group) {
          where.groupedExpressions.push(
            this.filterGroupToWhere(filter, group.connector)
          )
        } else {
          where.groupedExpressions.push(
            this.filterToWhere(filter, group.connector)
          )
        }
      }
      return where
    }
  },
  apollo: {
    models: {
      query() {
        const fields = this.getFieldsGql('read', 'Model', [
          'id',
          'name',
          'modelNumber',
          { name: 'manufacturer', type: 'Manufacturer', fields: ['id', 'name'] }
        ])
        return this.$gql`
          query models($where: [WhereExpression])
          {
            models(where: $where, orderBy: [{ path: "name" }, { path: "modelNumber" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      variables() {
        return {
          where:
            this.isAllAssetsPage || !this.currentType
              ? null
              : [
                  {
                    path: 'assetType',
                    value: this.currentType.singleQueryName,
                    comparison: 'equal'
                  }
                ]
        }
      },
      skip() {
        return !this.ability.can('read', 'Model')
      },
      update({ models }) {
        return models.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get models. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (
      this.isAssetPage &&
      !this.ability.can('read', this.currentType.graphTypeName)
    ) {
      this.$router.push('/')
    }
    this.search = ''
  }
}
