import AssetMixin from '@/mixins/asset.mixin'
import ApiService from '@/services/api.service'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'

export default {
  mixins: [AssetMixin],
  props: ['show'],
  data() {
    return {
      dialog: false,
      loading: false,
      selectedType: {},
      processingFile: false,
      file: null,
      validFile: false,
      emptyFile: false,
      missingFields: [],
      uploadArray: [],
      chunkError: [],
      uploadResult: { successCount: 0, totalCount: 0, failures: [] },
      invalidRows: [],
      totalRows: 0,
      uploadErrors: [],
      parsedRows: 0,
      emptyRows: 0,
      uploadedRows: 0,
      progressText: '',
      uploading: false,
      uploaded: false,
      showHelpDialog: false,
      helpTab: null
    }
  },
  computed: {
    importChunkSize() {
      return this.$store.state.settings.importChunkSize
    },
    importThreadCount() {
      return this.$store.state.settings.importThreadCount
    },
    availableTypes() {
      const options = this.isAllAssetsPage
        ? this.assetTypes
            .filter(type => this.ability.can('import', type.graphTypeName))
            .map(type => ({
              value: type.graphTypeName,
              text: _.startCase(type.graphTypeName),
              icon: type.icon,
              importFields: type.importFields
            }))
        : [
            {
              value: this.currentType.graphTypeName,
              text: _.startCase(this.currentType.graphTypeName),
              icon: this.currentType.icon,
              importFields: this.currentType.importFields
            }
          ]
      this.selectedType = options[0]
      return options
    },
    confirmMessage() {
      if (this.file) {
        const fname = this.file.name.toLowerCase()
        for (var at of this.assetTypes) {
          if (at.graphTypeName !== this.selectedType.value) {
            if (fname.includes(at.graphTypeName.toLowerCase())) {
              return at.graphTypeName
            } else if (fname.includes(_.startCase(at.graphTypeName).toLowerCase())) {
              return _.startCase(at.graphTypeName)
            }
          }
        }
      }
      return ''
    }
  },
  watch: {
    show(v) {
      this.dialog = v
    },
    dialog(v) {
      this.$emit('update:show', v)
      if (!v && !this.uploaded) {
        this.file = null
        this.onFileChange(this.file)
      }
    },
    selectedType() {
      this.file = null
      this.onFileChange(this.file)
    }
  },
  methods: {
    async onFileChange(file) {
      this.missingFields = []
      this.uploadArray = []
      this.uploaded = false
      this.validFile = false
      this.emptyFile = false
      this.totalRows = 0

      if (file) {
        this.processingFile = true

        // check the header, and number of rows
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          step: (results, parser) => {
            if (this.totalRows === 0) {
              // check if csv header includes all fields, all fields are required for now
              this.missingFields = this.selectedType.importFields.filter(field => !results.meta.fields.includes(field))
              this.validFile = _.isEmpty(this.missingFields)
              if (!this.validFile) {
                parser.abort()
                this.processingFile = false
              }
            }
            this.totalRows++
          },
          complete: () => {
            this.processingFile = false
            this.emptyFile = this.totalRows === 0
          }
        })
      }
    },
    submit() {
      let chunk = []

      this.uploadArray = []
      this.uploading = true
      this.uploaded = false
      this.uploadResult = { successCount: 0, totalCount: 0, failures: [] }
      this.uploadedRows = 0
      this.parsedRows = 0
      this.emptyRows = 0
      
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        step: (results, parser) => {
          this.parsedRows++
          let isEmptyRow = true
          for (let field of results.meta.fields) {
            if (!_.isEmpty(results.data[field])) {
              isEmptyRow = false
              break
            }
          }
          if (isEmptyRow) {
            this.emptyRows++
            this.uploadedRows++
          }
          else {
            this.progressText = `${Math.round(
              (this.parsedRows / this.totalRows) * 100
            )}% - Parsing row #${this.parsedRows}.`
  
            if (!_.isEmpty(results.data)) {
              if (_.isEmpty(results.error)) {
                chunk.push(results.data)
              } else {
                this.invalidRows.push({
                  success: false,
                  validationResults: results.error,
                  data: results.data
                })
              }
            }
            // upload *importChunkSize* rows at once to prevent timeout on stg / prod
            if (chunk.length >= this.importChunkSize) {
              parser.pause()
              this.upload(chunk, parser)
              chunk = []
            }
          }
        },
        complete: async() => {
          if (chunk.length > 0) {
            await this.upload(chunk)
          }
          this.uploadComplete()
        }
      })
    },
    uploadComplete() {
      // wait for all upload to finish
      if (this.uploadArray.filter(u => u.uploading).length === 0) {
        this.uploading = false
      }
      if (this.uploading) {
        setTimeout(() => this.uploadComplete(), 100)
      } else {
        for (let upload of this.uploadArray) {
          this.uploadResult.successCount += upload.result.successCount
          this.uploadResult.totalCount += upload.result.totalCount
          this.uploadResult.failures = this.uploadResult.failures.concat(upload.result.failures)
        }
        this.progressText = '100% - File uploaded.'
        this.uploaded = true
        this.file = null
        this.validFile = false
      }
    },
    upload(chunk, parser = null) {
      const idx = this.uploadArray.length
      this.uploadArray.push({
        uploading: true,
        result: {
          successCount: 0,
          totalCount: chunk.length,
          failures: []
        }
      })
      // prepare the file and the upload data
      const formData = new FormData()
      formData.append('file', new Blob([Papa.unparse(chunk, { columns: this.selectedType.importFields })]))
      formData.set('type', this.selectedType.value)
      if (parser && parser.paused() && this.uploadArray.filter(u => u.uploading).length < this.importThreadCount) {
        parser.resume()
      }

      return ApiService.customRequest({
        method: 'post',
        url: '/import',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
      })
        .then(({ data }) => {
          this.uploadArray[idx].result = {
            successCount: data.successCount,
            totalCount: data.totalCount,
            failures: data.failures
          }
        })
        .catch(error => {
          this.uploadArray[idx].result = {
            successCount: 0,
            totalCount: chunk.length,
            failures: chunk
          }
          this.uploadErrors.push({ chunk, error })
        })
        .finally(() => {
          this.uploadArray[idx].uploading = false
          this.uploadedRows += chunk.length
          if (parser && parser.paused()) {
            parser.resume()
          }
        })
    },
    exportFailedRows() {
      if (this.uploadResult) {
        let data = this.uploadResult.failures.map(f => {
          const error = Array.isArray(f.validationResults)
            ? f.validationResults.map(vr => vr.errorMessage).join('\n')
            : ''
          return { ..._.mapKeys(f.data, (v, k) => _.upperFirst(k)), error }
        })
        const dt = this.$moment().format('YYYY-MM-DD-HH-mm-ss')
        const fileName = `${this.file.name.replace(
          '.csv',
          ''
        )} - failed rows ${dt}.csv`
        this.download(
          new Blob([
            Papa.unparse(data, { columns: this.selectedType.importFields })
          ]),
          fileName
        )
      }
    },
    downloadTemplate(type) {
      const fileName = `${type.text} template.csv`
      this.download(new Blob([Papa.unparse([type.importFields])]), fileName)
    },
    download(blob, fileName, type = 'text/csv') {
      saveAs(blob, fileName, { type })
    },
    getFailedItemDisplayName(row) {
      let name
      switch (this.selectedType.value) {
        case 'Sim':
          name = _.get(row, 'data.phoneNumber')
          break
        default:
          name = _.get(row, 'data.name')
          break
      }
      if (_.isEmpty(name)) {
        name = row.data ? JSON.stringify(row.data) : JSON.stringify(row)
      }
      return name
    }
  }
}
