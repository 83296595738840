import AssetDataTable from '@/components/Asset/AssetDataTable/AssetDataTable.vue'
import CustomizeColumn from '@/components/Asset/CustomizeColumn/CustomizeColumn.vue'
import ImportDialog from '@/components/Asset/ImportDialog/ImportDialog.vue'
import Share from '@/components/Asset/Share/Share.vue'
import ExportMixin from '@/mixins/export.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  props: ['filterId', 'filterProp', 'searchProp', 'updatedItem'],
  mixins: [QueryMixin, ExportMixin],
  components: {
    AssetDataTable,
    CustomizeColumn,
    ImportDialog,
    Share
  },
  data() {
    return {
      assets: [],
      assetsCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: '',
      debouncing: false,
      debounceTimeout: null,
      filter: {},
      showImportDialog: false,
      assetFilter: null,
      assetFilterLoaded: false,
      assetDialogOpen: false
    }
  },
  computed: {
    allowedRegions() {
      return this.regions
        .filter((r) =>
          this.ability.can('read', {
            __typename: this.currentType.graphTypeName,
            region: r.text.toUpperCase()
          })
        )
        .map((r) => r.text.toUpperCase())
    },
    canCreateAnyAsset() {
      return (
        this.assetTypes.filter((at) =>
          this.ability.can('create', at.graphTypeName)
        ).length > 0
      )
    },
    canImportAnyAsset() {
      return (
        this.assetTypes.filter((at) =>
          this.ability.can('import', at.graphTypeName)
        ).length > 0
      )
    },
    isTrash() {
      if (this.$route.name === 'list-deleted-assets') {
        return true
      } else {
        return false
      }
    },
    title() {
      if (this.isTrash) {
        return 'Deleted Assets'
      } else {
        return this.isAssetPage
        ? _.startCase(this.currentType.name)
        : _.get(this.assetFilter, 'name')
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.reload()
      },
      deep: true
    },
    search() {
      this.reload()
    },
    filter: {
      handler() {
        this.reload()
      },
      deep: true
    },
    filterProp: {
      handler() {
        if (this.filterProp) {
          this.filter = this.filterProp
        }
      }
    },
    searchProp: {
      handler() {
        if (this.searchProp) {
          this.search = this.searchProp
        }
      }
    }
  },
  methods: {
    openAssetDialog(asset) {
      if (asset) {
        this.assetDialogOpen = true
      } else {
        this.assetDialogOpen = false
      }
    },
    onFilterUpdate(filter) {
      // if filter is updated in asset filter page, redirect user to the asset type page
      if (!_.isEqual(this.filter, filter)) {
        if (this.isAssetFilterPage) {
          this.$router.push({
            name: 'list-assets',
            params: { type: this.currentType.name, filterProp: filter }
          })
        } else {
          this.filter = filter
        }
      }
    },
    getAssetsVariables() {
      let whereExpression = this.filterToWhereExpression(this.filter)

      let isDeletedFilter = {
        connector: 'and',
        groupedExpressions: [{path: 'isDeleted', comparison: 'equal', value: this.isTrash, negate: false, connector: 'and'}],
        negate: false
      }
      whereExpression.unshift(isDeletedFilter)

      return {
        take:
          this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
        skip:
          this.options.itemsPerPage === -1
            ? 0
            : this.options.itemsPerPage * (this.options.page - 1),
        orderBy: this.getOrderBy(),
        where: whereExpression,
        regions: this.allowedRegions,
        q: this.search
      }
    },
    showImport() {
      if (this.showImportDialog) {
        this.showImportDialog = false
      }
      this.$nextTick(() => (this.showImportDialog = true))
    },
    reload() {
      this.$apollo.queries.assets.refetch(this.getAssetsVariables())
    },
    tagClicked(tag) {
      this.search = tag
    }
  },
  apollo: {
    assets: {
      query() {
        const allFields = [
          'id',
          'name',
          'memo',
          'costCode',
          'serialNumber',
          'purchaseCost',
          'purchaseCurrencyCode',
          'purchaseDate',
          'status',
          'operationStatus',
          'warrantyExpires',
          'region',
          'countryIsoCode2',
          {
            name: 'model',
            type: 'Model',
            fields: [
              'id',
              'name',
              'modelNumber',
              'modelYear',
              'assetType',
              {
                name: 'manufacturer',
                type: 'Manufacturer',
                fields: ['id', 'name']
              }
            ]
          },
          {
            name: 'office',
            type: 'Location',
            fields: ['id', 'name']
          },
          {
            name: 'company',
            type: 'Company',
            fields: ['id', 'name', 'nameLocal', 'companyId']
          },
          {
            name: 'assignee',
            type: 'Assignee',
            fields: [
              'id',
              {
                type: 'IntraAccount',
                fields: ['samAccountName'],
                isFragment: true
              }
            ]
          },
          {
            name: 'primaryUser',
            type: 'IntraAccount',
            fields: [
              'id',
              'samAccountName'
            ]
          },
          {
            name: 'latestDeleteHistory',
            type: 'History',
            fields: [
              'tokenSubject',
              'createdAt'
            ]
          }
        ]
          .concat(this.extraFields.filter((f) => !f.fields).map((f) => f.name))
          .concat(this.extraFields.filter((f) => f.fields && !f.readOnly))
          .concat(this.readOnlyFields)
        const fields = this.getFieldsGql(
          'read',
          this.currentType.graphTypeName,
          allFields
        )
        return this.$gql`
          query assets($take: Int, $skip: Int, $orderBy: [OrderBy], $where: [WhereExpression], $q: String!, $regions: [Region!]) {
            ${this.currentType.listQueryName}(take: $take, skip: $skip, orderBy: $orderBy, where: $where, search: $q, regions: $regions) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return this.getAssetsVariables()
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          _.isEmpty(this.currentType) ||
          !this.ability.can('read', this.currentType.graphTypeName) ||
          (this.isAssetFilterPage && this.assetFilter === null)
        )
      },
      update: () => [],
      result({ data, error }) {
        if (data && data[this.currentType.listQueryName]) {
          if (this.updatedItem !== undefined) {
            for (
              let i = 0;
              i < data[this.currentType.listQueryName].items.length;
              i++
            ) {
              if (
                data[this.currentType.listQueryName].items[i].id ===
                this.updatedItem.id
              ) {
                data[this.currentType.listQueryName].items[i] = this.updatedItem
                break
              }
            }
          }
          this.assets = data[this.currentType.listQueryName].items
          this.assetsCount = data[this.currentType.listQueryName].totalCount

          if (
            Math.ceil(this.assetsCount / this.options.itemsPerPage) <
            this.options.page
          ) {
            this.options.page = 1
          }
        } else if (error) {
          this.graphQLOnError(`Failed to get assets. ${error.toString()}`)
        }
      }
    },
    assetFilter: {
      query() {
        const fields = this.getFieldsGql('read', 'AssetFilter', [
          'id',
          'name',
          'scheduledReport',
          'cron',
          'type',
          'settings',
          'where',
          'creator'
        ])
        return this.$gql`
          query assetFilter($id: ID!) {
            assetFilter(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: parseInt(this.filterId) }
      },
      skip() {
        return !this.isAssetFilterPage
      },
      update: () => [],
      result({ data, error }) {
        if (data && data.assetFilter) {
          let settings = JSON.parse(data.assetFilter.settings)
          this.filter = settings.localFilter
          this.assetFilter = data.assetFilter
          this.assetFilterLoaded = true
        } else if (error) {
          this.graphQLOnError(
            `Failed to load asset filter. ${error.toString()}`
          )
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.assetDialogOpen) {
      this.$refs.assetsDatatable.closeAssetDialog()
      return next(this.$route)
    } else {
      return next()
    }
  },
  created() {
    if (this.filter && this.filter.filterType !== 'Asset') {
      this.filter = {}
    }
    if (this.filterProp) {
      this.filter = this.filterProp
    }
    if (this.searchProp) {
      this.search = this.searchProp
    }
  }
}
